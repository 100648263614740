/* Change the background color of the left and right buttons */
.slick-prev,
.slick-next {
  background-color: rgb(0, 0, 0); /* Default button color */
  color: rgb(207, 83, 83); /* Arrow color */
  border: 1px solid black; /* Optional border */
  border-radius: 50%; /* Optional rounded corners */
}

/* Change the button color on hover */
.slick-prev:hover,
.slick-next:hover {
  background-color: black; /* Hover background color */
  color: white; /* Hover arrow color */
}

.carousel-container {
  width: 95%;
  margin: auto;

  .sliderBox {
    cursor: pointer;
    .singlebox {
      img {
        &:hover {
          transform: scale(1.05);
          transition: 0.3s ease-in-out;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  /* Change the background color of the left and right buttons */
  .slick-prev,
  .slick-next {
    display: none !important;
  }

  /* Change the button color on hover */
  .slick-prev:hover,
  .slick-next:hover {
    display: none !important;
  }
}
