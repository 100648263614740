// .mainCategories {
//   height: 100%;
//   width: 100%;
//   box-sizing: border-box;
//   overflow: hidden;
//   .innerCot {
//     .productMaindiv {
//       display: flex;
//       flex-wrap: wrap;
//       justify-content: space-around;
//       gap: 10px;
//       padding: 3rem 0;
//       .products {
//         width: 32%;
//         height: 370px;
//         border-radius: 10px;
//         overflow: hidden;

//         .imgdiv {
//           width: 100%;
//           height: 80%;
//           img {
//             width: 100%;
//             height: 100%;
//             // border-radius: 10px;
//           }
//         }
//         .details {
//           padding: 10px;
//           text-align: center;
//         }
//       }
//     }
//   }
// }

.dropdowndiv {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  margin-top: 2rem;
}

.category-list {
  list-style: none;
  padding: 0;
}

// .category-list li {
//   padding: 10px;
//   cursor: pointer;
//   border: 1px solid #ccc;
//   margin-bottom: 5px;
// }

.category-list li.active {
  // background-color: #007bff;
  color: rgb(182, 61, 31);
  font-weight: bold;
}

.dropdowndiv {
  .form-select {
    display: none;
    width: 40% !important;
  }
}

@media (max-width: 900px) {
  .dropdowndiv {
    .form-select {
      display: block;
    }
  }
}

@media (max-width: 767px) {
  .dropdowndiv {
    .form-select {
      // display: none;
      width: 50% !important;
      font-size: 1rem;
    }
  }
}

@media (max-width: 550px) {
  .dropdowndiv {
    flex-direction: column;
    .form-select {
      margin-top: 1rem;
      width: 100% !important;
      font-size: 1rem;
    }
  }
}
