.outer-main {
  height: 100%;
  width: 100%;
  .carousel {
    height: 100%;
    .carousel-inner {
      height: 100%;
      width: 100%;
      .carousel-item {
        // height: 79vh;
        // border: 1px solid red;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .outer-main {
    width: 100%;
    margin: 0;
    padding: 0;
    .carousel {
      .carousel-inner {
        .carousel-item {
          height: 100%;
          width: 100%;
          height: 60vh;

          img {
            height: 100%;
            width: 100%;
            object-fit: fill;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .outer-main {
    .carousel {
      .carousel-inner {
        .carousel-item {
          height: 100%;
          width: 100%;
          height: 30vh;
        }
      }
    }
  }
}
