.directors {
  .mahesh {
    padding: 1rem 0;
  }
}

/* Change the background color of the left and right buttons */
.slick-prev,
.slick-next {
  background-color: rgb(0, 0, 0); /* Default button color */
  color: rgb(207, 83, 83); /* Arrow color */
  border: 1px solid black; /* Optional border */
  border-radius: 50%; /* Optional rounded corners */
}

/* Change the button color on hover */
.slick-prev:hover,
.slick-next:hover {
  background-color: black; /* Hover background color */
  color: white; /* Hover arrow color */
}

.carousel {
  .carousel-inner {
    width: 90%;
    margin: auto;
    .carousel-item {
      width: 100%;
      text-align: center;

      img {
        height: 400px;
        margin: auto;
        padding: 1rem;
      }
    }
  }
}
.slick-dots li button:before {
  color: rgb(73, 223, 36); /* Change this to your desired dot color */
}

.slick-dots li.slick-active button:before {
  color: black; /* Change this to your desired active dot color */
}

@media (max-width: 990px) {
  .about-tem {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    .left-about-img {
      margin-top: 1rem !important;
      width: 90%;
    }
    .right-contect {
      width: 100%;
      text-align: justify;
      padding: 0 16px !important;
    }
  }
}
@media (max-width: 900px) {
  .directors {
    .mahesh {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
  .carousel {
    .carousel-inner {
      .carousel-item {
        img {
          height: 100%;
          margin: auto;
          padding: 1rem;
        }
      }
    }
  }

  .tem-vision {
    .vision-left {
      width: 100%;
      padding: 0 16px !important;
    }
    .vision-img {
      width: 100%;
    }
  }

  .directors {
    .varadraj {
      .varad-con {
        width: 100%;
        padding: 0 16px !important;
      }
    }
    .mahesh {
      .mahesh-con {
        width: 100%;
        padding: 0 16px !important;
      }
    }
  }
}
.slick-dots li button:before {
  color: rgb(73, 223, 36); /* Change this to your desired dot color */
}

.slick-dots li.slick-active button:before {
  color: black; /* Change this to your desired active dot color */
}

@media (max-width: 500px) {
  .carousel {
    .carousel-inner {
      .carousel-item {
        img {
          padding: 0;
          width: 100% !important;
        }
      }
    }
  }
}
