.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.container {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: space-around;
  align-items: center;
  /* padding: 3rem 5rem; */
}

.contact_header {
  /* height: 300px; */
  /* background-size: cover; */
  /* background-color: rgb(134, 134, 134); */
  /* background: red; */
  /* background-blend-mode: multiply; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  font-size: 30px;
  font-weight: 500;
  margin-top: 2rem;
  text-align: center;
  /* color: white; */
  /* background-position: center; */
}

.servicecon {
  width: 100%;
  height: 100%;
}
.service1 {
  display: flex;
  justify-content: center;
}
.service2{
  display: flex;
  justify-content: center;
}
.leftImg {
  width: 45%;
}
.leftImg img {
  overflow: hidden;
  height: 100%;
  width: 100%;
  &:hover {
    transform: scale(0.95);
    transition: 0.3s linear;
  }
}
.rightCon {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 10px;
  text-align: justify;
  padding: 30px;
}

.servicardMain {
  text-align: center;
}

.cardWrapper {
  height: 270px;
  width: 270px;
  background-size: cover;
  background-color: var(--light-shadow);
  /* background: #72716a; */
  /* background-color: #66655de8; */
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--bg-light);
  transition: 0.3s ease-in-out all;

  &:hover {
    cursor: pointer;
    background-color: var(--dark-accent);
    background-color: #212c2b;
    border-top-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }
}

.serviceLogo {
  font-size: 4rem;
}

.serviceName {
  margin-top: 7px;
  font-size: 1.1rem;
  font-weight: 400;
  text-align: center;
}

.servicardMain > button {
  width: 100%;

  border: 1px solid black;
  padding: 5px 0;
  margin-top: 7px;
  border-radius: 15px;
  color: white;
  background: #c1071da4;

  font-size: 0.9rem;

  &:hover {
    background-color: #c1071dc7;
  }
}

@media (max-width: 1020px) {
  .container {
    padding: 2rem;
    gap: 1rem;
  }

  .cardWrapper {
    height: 230px;
    width: 230px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 768px) {
  .container {
    width: 90%;
  }
  .cardWrapper {
    height: 200px;
    width: 200px;

  }
  .service1 {
    display: flex;
    flex-direction: column-reverse;
  }
  .service2{
    flex-direction: column;
  }
  .leftImg {
    width: 80%;
    margin: auto;
  }
  .rightCon {
    width: 100%;
  }
}
@media (max-width: 580px) {
  .container {
    padding: 1rem;
  }
}

@media (max-width: 510px) {
  .cardWrapper {
    height: 250px;
    width: 250px;
  }
}
