.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-top: 3rem;
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #dbffe0;
  /* margin: 10% auto; */
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* .cardproduct { */
.row {
  width: 98%;
  margin: auto;
  justify-content: space-around;
}
.imgcard {
  @media (max-width: 580px) {
    width: 45%;
    gap: 10px;
  }
  &:hover {
    transform: scale(0.95);
    transition: 0.3s ease-in-out;
  }
}
/* } */

@media screen and (max-width: 450px) {
  .modal-content {
    width: 80%;
  }
}
@media screen and (max-width: 900px) {
 .row{
  gap: 10px;
 }
}
