.navMain {
  .innerDiv {
    height: 100%;
    width: 100%;
    display: flex;
    .div1,
    .div2,
    .div3,
    .div4 {
      width: 25%;
      border: 1px solid red;
    }
  }
}
