
.navbar {
  background-color: rgb(255, 255, 255);
  position: relative;
  padding: 0.8rem;
}

/* Active link styles */
.activeLink {
  color: #af252e;
  border-bottom: 1px solid #af252e;
}
.headtext {
  color: black;
  transition: transform 0.1s ease-in-out;
  &:hover {
    color: #af252e;
    border-bottom: 1px solid #af252e;
  }
}

/* Active link styles */
.activeLink {
  color: #AF252E; 
  border-bottom: 1px solid #AF252E; 
}

/* Active link styles */
.activeLink {
  color: #AF252E; 
  border-bottom: 1px solid #AF252E; 
}

.container {
  /* margin: 0 auto; */
  padding: 0 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.logoContainer {
  height: 80px;
  width: 175px;
}

.logo {
  height: 100%;
  width: 100%;
  /* object-fit: cover; */
  cursor: pointer;
}

.menu_icon {
  display: none;
}

.closeIcon {
  display: none;
}

.btnContainer {
  display: flex;
  gap: 1rem;
  padding: 1rem;
}

.mobileheader {
  display: none;
}

.nav_elements {
  display: none;
}

.navheader > ul > li {
  cursor: pointer;
  color: black;
}

@media (max-width: 900px) {
  .menu_icon {
    display: block;
    cursor: pointer;
  }
  .nav_elements {
    display: block;
  }
  .navheader {
    display: none;
  }
}

@media (max-width: 768px) {
  .call {
    margin-left: 0px;
  }
}

@media (max-width: 900px) {
  .container {
    /* padding: 0 3rem; */
  }

  .nav_elements {
    position: fixed;
    right: -300px;
    top: 0;
    background: white;
    z-index: 100;
    height: 100vh;
    width: 300px;
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav_elements.active {
    right: 0;
  }

  .closeIcon {
    position: absolute;
    top: 10px;
    right: 43px;
    background: var(--bg-primary);
    padding: 10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebarOverlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: rgba(1, 1, 1, 0.5);
  }

  .btnContainer {
    margin-top: 3rem;
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 0 1rem;
  }

  .logoContainer {
    height: 50px;
    width: 80px;
  }
}

/* media queries for tablets  */
@media screen and (min-width: 600px) and (max-width: 900px) {
  .nav_elements ul {
    gap: 1rem;
  }
}
