.catogoreis {
  width: 90%;
  height: 100%;
  display: flex;
  margin: auto;
  margin-top: 2rem;
  //   display: none;

  .cat {
    width: 33%;

    padding-left: 2rem;
    border-right: 1px solid red;

    .link {
      color: black;
      font-weight: 600;
      font-size: 1.3rem;
    }
    ul {
      margin-top: 1rem;

      li {
        text-align: start;
        list-style-type: none;
        font-size: 1rem;
        margin-top: 10px;
        color: black;
        .linktxt {
          margin-top: 5rem;
          color: black !important;
        }
      }
    }
  }
}

// .nav-header {
//   .list-unstyled {
//     .allcategories {
//       border: 1px solid red;
//     }
//     .allcategories:hover {
//       border: 1px solid rgb(123, 255, 0);

//       .catogoreis {
//         display: contents;
//       }
//     }
//   }
// }

@media (max-width: 900px) {
  .catogoreis {
    display: none;
  }
}
