.SingleImgDetails {
  height: 100%;
  width: 100%;
  padding: 2rem 0;
  overflow: hidden;
  .row {
    .img {
      img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
      }
    }
    .details {
      padding: 20px;

      @media (max-width: 768px) {
        padding: 20px 40px;
      }
    }
  }
  .cardproduct {
    .row {
      justify-content: space-around;
      .imgcard {
        @media (max-width: 580px) {
          width: 45%;
          gap: 10px;
        }
        &:hover {
          transform: scale(0.95);
          transition: 0.3s ease-in-out;
        }
      }
    }
  }

  //
  .form-outer {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000027;
    position: fixed;
    top: 0;
    left: 0;
    .inquiryForm {
      background-color: #f9f9f9;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      max-width: 650px;
      margin: 20px auto;

      border: 1px solid rgba(126, 126, 126, 0.699);

      form {
        .input-div {
          display: flex;
          gap: 10px;

          .labeldiv {
            width: 100%;
            position: relative;
            margin-top: 13px;
            label {
              background: white;
              padding: 2px 5px;
              position: absolute;
              top: -25%;
              left: 5%;
            }
            input {
              width: 100%;
              padding: 10px;
              border: 1px solid rgba(126, 126, 126, 0.699);
              border-radius: 5px;
              outline: none;
              margin-bottom: 10px;
            }
            textarea {
              width: 100%;
              border-radius: 5px;
              padding: 10px;

              border: 1px solid rgba(126, 126, 126, 0.699);
            }
          }
        }
        button {
          background-color: #c1071e;
          color: white;
          padding: 10px 30px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: 0.3s ease;
        }
      }
    }
  }
}

// responsive
@media (max-width: 680px) {
   .SingleImgDetails {
  .form-outer {
    .inquiryForm {
      max-width: 80%;
      form {
        .input-div {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }
  }
  }
}
