.outercat {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  .innerCot {
    .productMaindiv {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 2rem;
      padding-bottom: 3rem;
      // border: 1px solid red;
      .products {
        width: 23%;
        height: 90%;
        // height: 330px;
        height: 100%;
        border: 1px solid rgba(128, 128, 128, 0.164);
        cursor: pointer;
        border-radius: 10px;
        overflow: hidden;

        .imgdiv {
          width: 100%;
          height: 80%;
          img {
            width: 100%;
            height: 100%;
            &:hover {
              transform: scale(1.05);
              transition: 0.3s ease-in-out;
            }
          }
        }
        .details {
          padding: 10px;
          text-align: center;
          color: black;
          font-size: 14px;
          h6 {
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media (max-width: 990px) {
  .outercat {
    .innerCot {
      .productMaindiv {
        gap: 0;
        .products {
          width: 30%;
          // height: 45vh;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .outercat {
    .innerCot {
      .productMaindiv {
        gap: 0;
        .products {
          width: 30%;
          // height: 38vh;
          // padding-bottom: 38px;
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .outercat {
    .innerCot {
      .productMaindiv {
        gap: 0;
        .products {
          width: 45%;
          padding-bottom: 38px;
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .outercat {
    .innerCot {
      .productMaindiv {
        .products {
          width: 45%;
          padding-bottom: 38px;
          .details{
            font-size: 12px;
            h6{
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
