.FirstDivOFClient {
  text-align: center;
  padding: 20px;

  .allClientTitle {
    margin-bottom: 20px;
    font-size: 2rem;
    font-weight: bold;
  }

  .listOfAllClient {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 150px;
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 10px;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}
