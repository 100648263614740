.Gly-container {
  height: 100%;
  width: 100%;
  padding: 3rem 0;

  .heading {
    display: flex;
    gap: 20px;
    justify-content: center;
  }

  .gallery.active,
  .catalogue,
  .active {
    color: black;
    font-weight: bold;
  }

  .inner-con {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.2rem;

    .images {
      width: 23%;
      border-radius: 5px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.404);
    z-index: 1000;
    .close {
      position: absolute;
      top: 10px;
      right: 25px;
      color: #f81616;
      font-size: 35px;
      cursor: pointer;
    }
    .modal-content {
      // background: transparent;
      // padding: 0;
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
      cursor: zoom-in;
    }
    .modal-content.zoomed {
      transform: scale(1.5); /* Adjust zoom level as needed */
      cursor: zoom-out;
    }
  }

  .cataloge {
    .catalogue-outer {
      .catalogue-item {
        width: 100%;
        height: 100%;
        display: flex;
        .left-box {
          width: 50%;
          height: 100%;
        }
        .right-box {
          width: 50%;
          // height: 50vh;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding-left: 40px;
          h4 {
            color: #a41723;
          }
          a {
            button {
              border-bottom: 1px solid gray;
              padding-bottom: -5px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .Gly-container {
    .cataloge {
      .catalogue-outer {
        .catalogue-item {
          display: flex;
          .left-box {
            width: 50%;
            height: 100%;
          }
          .right-box {
            width: 50%;
            padding-left: 40px;
            h4 {
              font-size: 3vw;
            }
            a {
              button {
                font-size: 12px;
                padding-bottom: -10px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .Gly-container {
    .cataloge {
      .catalogue-outer {
        .catalogue-item {
          margin-top: 2rem;
          flex-direction: column;
          gap: 0px;
          .left-box {
            width: 100%;
            height: 100%;
            padding: 2rem;
          }
          .right-box {
            margin-top: -25px;
            width: 100%;

            padding-left: 40px;
            h4 {
              color: #a41723;
              font-size: 4vw;
            }
            a {
              button {
                font-size: 12px;
                padding-bottom: -10px;
              }
            }
          }
        }
        .box2 {
          display: flex;
          flex-direction: column-reverse;
        }
      }
    }
    .inner-con {
      .images {
        width: 30%;
      }
    }
  }
}
