.servicesHead {
  margin-top: 3rem;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
}

.faqContainer {
  margin: 5rem auto;
}

.contact_form {
  background-color: #dbffe0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90%;
  border-radius: 10px;
}

.formHeader {
  font-size: 20px;
  font-weight: 600;
}

.label {
  font-weight: 500;
}

.formInput {
  outline: none;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  font-size: 16px;
}

.submitBtn {
  background-color: var(--accent);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s ease-in-out all;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  font-size: 14px;

  &:hover {
    background-color: #25542b;
  }
}
/* .readmorebtn {
  border: 1px solid rgba(0, 0, 0, 0.479);
  width: 25%;
  padding: 8px 1rem;
  border-radius: 15px;
  
} */
/* &:hover{
  background-color: #942431;
  color: white;
} */
.readmorebtn {
  border: 1px solid rgba(0, 0, 0, 0.479);
  width: 25%;
  padding: 8px 1rem;
  border-radius: 15px;
  background-color: transparent;
  color: black;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #942431;
    color: white;
    transform: scale(1.05);
    border: none;
  }
}

.input_group > div {
  flex: 1;
}

.input_group {
  display: flex;
  gap: 1rem;
}

/* ABOUT SERVICES */
.aboutServices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3.5rem;
}

.servicesLeft {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.leftHead {
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 600;
}

.leftDesc {
  font-size: 0.95rem;
  color: var(--text-dark-grey);
}

.serviceImageContainer {
  width: 50%;
  height: 400px;
  height: 100%;
  text-align: center;
}

.aboutServicesImg {
  height: 80%;
  width: 80%;
  object-fit: cover;
  border-radius: 7px;
}

/* .logocontainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 4rem 0;
} */
.ButtonCheck {
  margin: auto;
  width: 8rem;
  padding: 10px;
  background-color: #942431;
}

.logocontainer {
  text-align: center;
  padding: 20px;
  margin-top: 1rem;

  h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .ourclient {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .logodiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 150px;
    border: 1px solid #ddd;
    border-radius: 10px;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .buttonCheck {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }
}
/* .ourclient {
  width: 90%;
  height: 30vh;
  margin: auto;
  display: flex;
  gap: 15px;
  overflow: scroll;
  scrollbar-color: transparent transparent;
} */
/* .ourclient::-webkit-scrollbar {
  width: none;
  display: none;
} */
/* .logodiv {
  margin-top: 1rem;
  height: 100px;
  width: 190px;
  flex-shrink: 0;
}
.logodiv > img {
  height: 100%;
  width: 100%;
} */
@media (max-width: 1100px) {
  .aboutServices {
    /* text-align: center; */
    /* height: 100%; */
    /* gap: 3rem; */
  }

  .serviceImageContainer {
    width: 70%;
    margin-bottom: 2rem;
  }

  .servicesLeft {
    width: 100%;
    gap: 1rem;
  }
}

@media (max-width: 720px) {
  .aboutServices {
    gap: 2rem;
    padding: 2rem;
  }

  .serviceImageContainer {
    width: 100%;
    height: 300px;
    height: 70%;
    width: 80%;
  }

  .faqContainer {
    margin: 2rem auto;
  }
  .readmorebtn {
    width: 30%;
  }
}

@media (max-width: 550px) {
  .leftHead {
    font-size: 1.5rem;
    line-height: 1.7rem;
  }

  .leftDesc {
    font-size: 0.85rem;
  }
  .readmorebtn {
    width: 35%;
  }
}

@media (max-width: 450px) {
  .aboutServices {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
  }

  .servicesLeft {
    align-items: center;
  }

  .leftHead {
    font-size: 1.3rem;
    line-height: 1.5rem;
    text-align: center;
  }

  .leftDesc {
    line-height: 1.1rem;
  }

  .contactFormAndAddress {
    flex-direction: column;
    padding: 30px;
    gap: 50px;
  }

  .locationHeader {
    text-align: center;
    padding: 5px;
  }

  .contact_locations {
    width: 100%;
  }

  .contact_form {
    width: 100%;
  }

  .submitBtn {
    width: 100%;
    padding: 10px 0px;
  }
}

/* media queries for tablets  */

@media screen and (min-width: 450px) and (max-width: 1100px) {
  .aboutServices {
    flex-direction: column-reverse;
  }
}

@media (max-width: 768px) {
  .servicesHead {
    margin: 0;
  }
}
@media (max-width: 390px) {
  .readmorebtn {
    width: 50%;
  }
}
